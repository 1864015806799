<template>
  <span>
    <input
      class="dataset-table__values-input"
      type="text"
      :id="`edit-vals-${clientQuestion._id.$oid}-${index}-${valType}`"
      :name="`${value.label}-value`"
      v-model="value.value"
    />
    <label
      class="dataset-table__values-label"
      :for="`edit-vals-${clientQuestion._id.$oid}-${index}-${valType}`"
    >
      <slot></slot>
    </label>
  </span>
</template>

<script>
import _ from "lodash"

export default {
  name: "DatasetTableValuesLabelInput",
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    index: {
      type: Number
    },
    // unique_values item
    value: {
      default: () => {},
      type: Object
    },
    valType: {
      default: () => "val",
      type: String
    }
  },
  created() {
    this.updateValue = _.debounce(this.updateValue, 1000)
  },
  data() {
    return {
      val: null
    }
  },
  methods: {
    updateValue() {
      this.$emit("update-value", this.val, this.valType)
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.value) this.val = val.value
      }
    },
    val: {
      immediate: false,
      handler() {
        if (
          this.$pigeonline.permissions.hasPermission("update-dataset") &&
          this.value.value !== parseInt(this.val)
        )
          this.updateValue()
      }
    }
  }
}
</script>
