<template>
  <span>
    <label
      class="dataset-table__values-value"
      :for="`edit-vals-label-${clientQuestion._id.$oid}-${index}-${valType}`"
    >
      <slot></slot>
    </label>
    <input
      class="dataset-table__values-input"
      placeholder="no label"
      type="text"
      :id="`edit-vals-label-${clientQuestion._id.$oid}-${index}-${valType}`"
      :name="`${value.value}-label`"
      v-model="label"
    />
  </span>
</template>

<script>
import _ from "lodash"
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "DatasetTableValuesLabelInput",
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    index: {
      type: Number
    },
    value: {
      default: () => {},
      type: Object
    },
    valType: {
      default: () => "val",
      type: String
    }
  },
  created() {
    this.saveLabel = _.debounce(this.saveLabel, 1000)
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      label: null
    }
  },
  methods: {
    saveLabel() {
      this.$emit("update-label", this.label, this.value)
    }
  },
  watch: {
    label: {
      immediate: false,
      handler() {
        if (
          this.$pigeonline.permissions.hasPermission("update-dataset") &&
          this.value.label !== this.label
        )
          this.saveLabel()
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.label && val.label.length > 0) this.label = val.label
      }
    }
  }
}
</script>
