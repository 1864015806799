<template>
  <div class="dataset-table__values-scale">
    <!--------------------------------------------
    |
    | EDIT OPTIONS
    |
    --------------------------------------------->
    <div class="dataset-table__values-scale-row1" v-if="!hideOptions">
      <button class="table__toolbar-btn" @click="showEditLabels">
        <SvgIconDecorative icon="tag" />
        <VisibleText>Edit Labels</VisibleText>
      </button>
      <button class="table__toolbar-btn" @click="showEditValues">
        <SvgIconDecorative icon="value" />
        <VisibleText>Edit values</VisibleText>
      </button>
      <div class="error-msg__inline">
        {{ message.err }}
      </div>
    </div>

    <!--------------------------------------------
    |
    | VALUES
    |
    --------------------------------------------->
    <article class="dataset-table__values-scale-section">
      <h2 class="dataset-table__values-scale-header valid-vals">
        <VisibleText>valid values</VisibleText>
      </h2>
      <ul class="dataset-table__values-scale-ul">
        <li
          v-for="(value, index) in values.valid"
          :key="`${clientQuestion._id.$oid}-valid-${index}`"
        >
          <DatasetTableValuesLabelInput
            :client-question="clientQuestion"
            :index="index"
            :value="value"
            val-type="valid"
            @update-label="updateLabel"
            v-if="isEditingLabels"
          >
            <span class="sr-only">
              <VisibleText>valid value</VisibleText>
            </span>
            {{ value.value }}
          </DatasetTableValuesLabelInput>
          <DatasetTableValuesEditValsInput
            :client-question="clientQuestion"
            :index="index"
            :value="value"
            val-type="valid"
            @update-value="updateValue"
            v-if="isEditingValues"
          >
            <span class="sr-only">
              <VisibleText>valid value</VisibleText>
            </span>
            {{ value.label || "no label" }}
          </DatasetTableValuesEditValsInput>
          <span v-show="!isEditingValues && !isEditingLabels">
            <span class="dataset-table__values-value">
              {{ value.value }}
            </span>
            <span class="dataset-table__values-label">
              {{ value.label || "no label" }}
            </span>
          </span>
        </li>
      </ul>
    </article>
    <article class="dataset-table__values-scale-section">
      <h2 class="dataset-table__values-scale-header invalid-vals">
        <VisibleText>invalid values</VisibleText>
      </h2>
      <ul class="dataset-table__values-scale-ul">
        <li
          v-for="(value, index) in values.invalid"
          :key="`${clientQuestion._id.$oid}-invalid-${index}`"
        >
          <DatasetTableValuesLabelInput
            :client-question="clientQuestion"
            :index="index"
            :value="value"
            val-type="invalid"
            @update-label="updateLabel"
            v-if="isEditingLabels"
          >
            <span class="sr-only">
              <VisibleText>invalid value</VisibleText>
            </span>
            {{ value.value }}
          </DatasetTableValuesLabelInput>
          <DatasetTableValuesEditValsInput
            :client-question="clientQuestion"
            :index="index"
            :value="value"
            val-type="invalid"
            @update-value="updateValue"
            v-if="isEditingValues"
          >
            <span class="sr-only">
              <VisibleText>invalid value</VisibleText>
            </span>
            {{ value.label || "no label" }}
          </DatasetTableValuesEditValsInput>
          <span v-show="!isEditingValues && !isEditingLabels">
            <span class="dataset-table__values-value">
              {{ value.value }}
            </span>
            <span class="dataset-table__values-label">
              {{ value.label || "no label" }}
            </span>
          </span>
        </li>
      </ul>
    </article>

    <!--------------------------------------------
    |
    | SAVE OPTIONS
    |
    --------------------------------------------->
    <div
      class="dataset-table__values-scale-save-options"
      v-show="isEditingValues || isEditingLabels"
    >
      <button
        class="dataset-table__values-scale-save-btn"
        @click="saveLabels"
        v-show="isEditingLabels"
      >
        <SvgIconDecorative icon="check" />
        Save Labels
      </button>
      <button
        class="dataset-table__values-scale-save-btn"
        @click="saveValues"
        v-show="isEditingValues"
      >
        <SvgIconDecorative icon="check" />
        Save Values
      </button>
      <button class="btn-cancel" @click="closeEditors">
        <SvgIconDecorative icon="remove" />
        Cancel
      </button>

      <span
        :class="[
          'dataset-table__values-msg',
          { 'success-msg__inline': message.success }
        ]"
      >
        {{ message.success }}
      </span>
      <span
        :class="[
          'dataset-table__values-msg',
          { 'error-msg__inline': message.err }
        ]"
      >
        {{ message.err }}
      </span>
    </div>
  </div>
</template>

<script>
// Components
import DatasetTableValuesEditValsInput from "./DatasetTableValuesEditValsInput.vue"
import DatasetTableValuesLabelInput from "./DatasetTableValuesLablelInput.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DataValuesMixin from "@/components/Dataset/DatasetTable/mixins/dataValuesMixin.js"

// Consts
import { SCALE_TYPE_TO_READABLE } from "@/utils/consts/constsDataTypes.js"

export default {
  name: "DatasetTableValuesScale",
  mixins: [DataValuesMixin],
  components: {
    DatasetTableValuesEditValsInput,
    DatasetTableValuesLabelInput,
    SvgIconDecorative
  },
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    }
  },
  data() {
    return {
      SCALE_TYPE_TO_READABLE: SCALE_TYPE_TO_READABLE,
      isEditingValues: false,
      newValues: {
        valid: [],
        invalid: []
      }
    }
  },
  computed: {
    /**
     * return subset of unique_values array
     */
    values() {
      let vals = {
        valid: [],
        invalid: []
      }
      if (
        Object.keys(this.SCALE_TYPE_TO_READABLE).includes(
          this.clientQuestion.approved_data_type
        )
      ) {
        vals.valid = this.getValueByIsValid(
          this.clientQuestion.unique_values,
          true
        )
        vals.invalid = this.getValueByIsValid(
          this.clientQuestion.unique_values,
          false
        )
      } else {
        vals.valid = this.getValueByIsValid(
          this.clientQuestion.unique_values,
          true
        )
      }
      return vals
    }
  },
  methods: {
    closeEditors() {
      this.resetMessage()
      this.isEditingValues = false
      this.isEditingLabels = false
    },
    getValueByIsValid(uniqueValues, isValid) {
      return uniqueValues.filter(item => item.is_valid === isValid)
    },
    updateValue(val, type) {
      let tempValue = {
        is_valid: type == "valid",
        value: parseInt(val, 10)
      }
      this.newValues[type].push(tempValue)
    },
    showEditLabels() {
      this.resetMessage()
      this.isEditingValues = false
      this.isEditingLabels = true
    },
    showEditValues() {
      this.resetMessage()
      this.isEditingLabels = false
      this.isEditingValues = true
    },
    validateValues() {
      const valid = this.values.valid.reduce((valids, valueObjs) => {
        valueObjs.value = parseInt(valueObjs.value, 10)
        valids.push(valueObjs)
        return valids
      }, [])
      const invalid = this.values.invalid.reduce((invalids, valueObjs) => {
        valueObjs.value = parseInt(valueObjs.value, 10)
        invalids.push(valueObjs)
        return invalids
      }, [])
      const valsObjs = [...valid, ...invalid]
      const allValues = valsObjs.map(v => v.value)
      const values = new Set(allValues)

      // are there any NaNs?
      if (allValues.includes(NaN))
        this.message.err = "Error: All values must be numbers."

      // all values are unique
      if (values.size !== allValues.length)
        this.message.err =
          "Error: There are duplicate values. All values must be unique."

      return { error: this.message.err.length > 0, uniqueValues: valsObjs }
    },
    async saveValues() {
      this.resetMessage()
      const validate = this.validateValues()
      if (validate.error) return
      try {
        await this.DATASETS_SERVICE.updateClientQuestion(
          this.clientQuestion._id.$oid,
          { unique_values: validate.uniqueValues }
        )
        this.message.success = "Success: Values saved."
      } catch (e) {
        this.message.err = "Error: Problem saving values."
        throw new Error("DatasetTableValuesScale:saveValues " + e.message)
      }
    }
  }
}
</script>
