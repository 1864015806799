// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      isEditingLabels: false,
      message: {
        err: "",
        success: ""
      },
      newLabels: null
    }
  },
  methods: {
    updateLabel(label, value) {
      // copy unique values object
      this.newLabels = this.newLabels
        ? this.newLabels
        : [...this.clientQuestion.unique_values]

      const indx = this.newLabels.findIndex(val => val.value === value.value)
      this.newLabels[indx].label = label
    },
    resetMessage() {
      this.message = {
        err: "",
        success: ""
      }
    },
    async saveLabels() {
      this.resetMessage()
      try {
        await this.DATASETS_SERVICE.updateClientQuestion(
          this.clientQuestion._id.$oid,
          { unique_values: this.newLabels }
        )
        this.message.success = "Success: Labels saved."
      } catch (e) {
        this.message.err = "Error: Problem saving labels."
        throw new Error("DatasetTableValuesScale:saveLabel " + e.message)
      }
    }
  }
}
